
import { Watch, Mixins, Component, InjectReactive } from "vue-property-decorator";
import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";

import { getStoreConfig, saveStoreConfig, listBookStoreByType } from "@/api/booking";

import { cloneDeep } from "lodash";

import { StoreStatusEnum } from "@/enum/store.enum";

import { IStoreBookingConfig, IStoreBookingService, IStoreConfigInfo } from "@/types/booking";

import { ERuntimeType } from "@/enum/booking.enum";


@Component({
    components: {
        ETable
    }
})
export default class BookingConfig extends Mixins(TablePagination, Resize) {
    @InjectReactive() currentTab!: string|number;

    storeStatusEnum = StoreStatusEnum;

    storeName = "";
    storeId = 0;
    selectStoreInfo: any = {};

    storeConfigInfo: IStoreConfigInfo = {
        bookConfig: {},
        services: [],
    };

    bookingFlag = false; // 总预约开关
    bookingRoomNum = 0; // 同时段可约房间数

    allStoreList: Array<any> = [];
    storeList: Array<any> = [];

    tableData: Array<IStoreBookingService> = [];
    loading = false;

    saveLoading = false;

    columns = [
        { label: "设备分类", prop: "deviceClassName" },
        { label: "服务显示名称", prop: "name" },
        { label: "服务时长（分钟）", prop: "runtime" },
        { label: "预约时间间隔", prop: "runtimeType" },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "可预约量", prop: "quantity", 'render-header': (h, { column, $index }) => {
            return h("div", [
                h('span',{ attrs: { class: "required-icon" } },['*']),
                h('span',{},[column.label]),
            ])
        } },
    ]

    mounted() {
        this.windowResize(300);
    }

    getStoreList() {
        listBookStoreByType().then((res) => {
            this.allStoreList = res.data;
            this.storeList = res.data;
            this.selectStoreInfo = this.storeList[0]
            this.storeId = this.selectStoreInfo.id;
            this.getStoreConfig(this.storeId)
        })
    }

    getStoreConfig(storeId: number) {
        this.loading = true;
        getStoreConfig(storeId).then((res) => {
            this.storeConfigInfo = res.data;
            this.tableData = this.storeConfigInfo.services; // 设置服务list
            this.bookingRoomNum = this.storeConfigInfo?.bookConfig?.maxServiceNum;
            this.bookingFlag = this.storeConfigInfo?.bookConfig?.bookSwtich || false;
        }).finally(() => {
            this.loading = false
        })
    }

    selectStore(store) {
        this.storeId = store.id;
        this.selectStoreInfo = store;
        this.getStoreConfig(this.storeId);
    }

    getRuntimeTypeDesc(runtimeType: ERuntimeType | null) {
        if (runtimeType == 1) return '30分钟';
        if (runtimeType == 2) return '1小时';
        return "";
    }

    save() {
        if (!this.checkConfig(true)) return;
        this.saveLoading = true;
        const params = {
            bookConfig: {
                bookSwtich: this.bookingFlag,
                maxServiceNum: this.bookingRoomNum,
                storeId: this.storeId,
            },
            services: this.tableData.map((el) => ({ bookServiceId: el.bookServiceId, quantity: el.quantity, storeId: this.storeId }))
        }
        saveStoreConfig(params).then((res) => {
            this.getStoreConfig(this.storeId);
            this.$message.success("操作成功");
        }).finally(() => {
            this.saveLoading = false;
        })
        
    }

    checkConfig(showMessage) {
        if (this.bookingRoomNum < 0) {
            showMessage && this.$message.warning("请输入可预约房间数");
            return false;
        }
        const _list = this.tableData.filter((el) => el.quantity < 0);
        if (_list.length) {
            showMessage && this.$message.warning("请输入可预约数量");
            return false;
        }
        return true;
    }

    @Watch("storeName", { immediate: true })
    filterStoreList(value: string) {
        if (!value) {
            this.storeList = cloneDeep(this.allStoreList);
            return;
        }
        const _list = this.allStoreList?.filter(el => el.storeName.toLocaleUpperCase().indexOf(value?.trim()?.toLocaleUpperCase()) > -1)
        this.storeList = cloneDeep(_list);
    }

    @Watch("currentTab", { immediate: true })
    getData() {
        if (this.currentTab != 2) return;
        this.storeName = "";
        this.getStoreList();
    }
}

