
import { Watch, Mixins, Component, InjectReactive } from "vue-property-decorator";
import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";

import { bookRecordList } from "@/api/booking";
import dayjs from "dayjs";

export interface IBookingListQueryForm {
    times: Array<any>,
    status: undefined | number | string,
    storeName: string,
    userPhone: string | number,
    [key: string]: any,
}

@Component({
    components: {
        ETable
    }
})
export default class BookingList extends Mixins(TablePagination, Resize) {
    @InjectReactive() currentTab!: string|number;

    queryForm: IBookingListQueryForm = {
        times: null,
        status: undefined,
        storeName: "",
        userPhone: "",
    }

    statusOptions = [
        {label: "待服务", value: 1},
        {label: "已取消", value: 2},
        {label: "已取消", value: 3},
        {label: "已失效", value: 4},
        {label: "已到店", value: 5},
    ];

    tableData = [];
    itemTotal = 0;

    loading = false

    columns = [
        { label: "预约门店", prop: "storeName"},
        { label: "服务项目", prop: "bookServiceName"},
        { label: "设备型号", prop: "deviceClassName"},
        { label: "预约人", prop: "userName"},
        { label: "手机号", prop: "userPhone"},
        { label: "预约状态", prop: "statusDesc"},
        { label: "预约留言", prop: "message"},
        { label: "预约时间", prop: "bookDateTime"},
        { label: "预约用户名", prop: "createdBy"},
        { label: "创建时间", prop: "createdTime"},
    ];

    mounted() {
        this.windowResize(350)
    }


    bookRecordList() {
        const { times, status, storeName, userPhone } = this.queryForm;
        const params = {
            bookDateEnd: times && dayjs(times[1]).format("YYYY-MM-DD 00:00:00"),
            bookDateStart: times && dayjs(times[0]).format("YYYY-MM-DD 23:59:59"),
            status,
            storeName,
            userPhone,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }
        this.loading = true;
        bookRecordList(params).then((res) => {
            res.data.list?.forEach(el => {
                el.bookDateTime = dayjs(el.bookDate).format("YYYY-MM-DD") + " " + el.bookTime;
                el.statusDesc = this.statusOptions?.find(el2 => el2.value == el.status)?.label;
            });
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.loading = false;
        })
    }

    @Watch("currentTab", { immediate: true })
    getData() {
        if (this.currentTab != 1) return;
        this.bookRecordList();
    }

    //重置分页参数
    resetPages() {
      this.currentPage = 1;
    }

    handleSearch() {
        this.resetPages();
        this.getData();
    }
}
