
  import { Watch, Component, Vue, Ref, ProvideReactive } from "vue-property-decorator";

  import BookingList from "./components/list.vue";
  import BookingConfig from "./components/bookingConfig.vue";
  import ServiceConfig from "./components/serviceConfig.vue";
  
  @Component({
    inject: [],
    components: {
      BookingList, BookingConfig, ServiceConfig
    }
  })
  export default class Booking extends Vue {
    @Ref("bookingListRef") readonly BookingListRef!: BookingList;
    @Ref("bookingConfigRef") readonly BookingConfigRef!: BookingConfig;
    @Ref("serviceConfigRef") readonly serviceConfigRef!: ServiceConfig;
  
    @ProvideReactive() currentTab = "1";
  }
  